import React from "react"

import Layout from "@components/layout"
import OffSiteLink from '@components/off-site-link'
import SEO from "@components/seo"

const HelpPage = () => (
  <Layout bodyClassName='help'>
    <SEO 
      title="Help & Support"
      description="How can we help you and your pup?" 
    />

    <div className="page">
      <div className="heroStripe">
        <div className="container">
          <h1 className="pageTitle">We&rsquo;re here to help.</h1>
          <p className="lede">Anything you need, straight from the founding team.</p>
        </div>
      </div>

      <div className="stripe">
        <div className="container" id="contact-options">
          <a href="mailto:nick@harper.dog" className="btn btn--tile">
            <span className="label">Drop us an email</span>
          </a>
          <a href="sms:1-513-818-2190" className="btn btn--tile">
            <span className="label">Text your question</span>
          </a>
          <a href="/feedback/" className="btn btn--tile">
            <span className="label">Send product feedback</span>
          </a>
          <a href="sms:1-513-818-2190" className="btn btn--tile">
            <span className="label">Schedule a personal welcome call</span>
          </a>
          <OffSiteLink href="https://www.aaha.org/pet_owner/about_aaha/hospital_search/default.aspx" className="btn btn--tile">
            <span className="label">Find a vet hospital</span>
          </OffSiteLink>
          <OffSiteLink href="https://apps.apdt.com/eweb/DynamicPage.aspx?webcode=TrainerSearch" className="btn btn--tile">
            <span className="label">Find a local trainer</span>
          </OffSiteLink>
        </div>
      </div>
    </div>
  </Layout>
);

export default HelpPage
